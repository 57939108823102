import React, { useEffect } from "react"
import EmergencyMedicalCareBanner from "../components/EmergencyMedicalCareBanner"
import Seo from "../components/Seo"
import Testimonials from "../components/Testimonials"
import withLayout from "../layout/withLayout"
import { encode } from 'html-entities';
const DoctorsPage = withLayout(({ siteData, contactData }) => {
  return (
    <>
      <Seo title="Contact Details" description="Contact us to know more, we are available 24x7 at your service." siteMetadata={siteData} />
      <div className="page-title-area page-title-bg-contact">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Contact Us</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="contact-info-area"
        style={{ backgroundColor: "#043E71" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="contact-info-box">
                <div className="icon">
                  <i className="flaticon-phone-call"></i>
                </div>
                <h3>Hospital and Emergency</h3>
                <span>
                  Address: {contactData.address}
                </span>
                <span>
                  Phone: <a href="#">{contactData.phone}</a>
                </span>
                <span>
                  Mobile: <a href="#">{contactData.mobile}</a>
                </span>
                <span>
                  Email: <a href="#">{encode(contactData.email)}</a>
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-3">
              <div className="contact-info-box">
                <div className="icon">
                  <i className="flaticon-email"></i>
                </div>
                <h3>Administration</h3>
                <span>
                  Bikky Sunar (GM) - <a href="#">gm@nemcaretezpur.com</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="container-fluid"
          style={{ minHeight: "30vh", padding: 0 }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3566.7714304739725!2d92.79324277271094!3d26.623772286944074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3744e960ee4fbacd%3A0x12b6b4a61f5ead2a!2sName%20care%20Unit%20of%20Ghy!5e0!3m2!1sen!2sin!4v1619640931286!5m2!1sen!2sin"
            height="450"
            style={{ border: "0", width: "100%", height: 450 }}
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </section>

      <EmergencyMedicalCareBanner siteData={siteData} />

      <Testimonials />
    </>
  )
})

export default DoctorsPage
